<template>
  <div>
    <div class="page-title">
      <h3>Создание бригады</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/teams')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <section v-else>
      <form class="form" @submit.prevent="submitHandler">
        <div class="input-field">
          <select id="department" ref="departmentSelect" v-model="department"
                  @change="onChangeDepartment($event)">
            <option value="" disabled selected>Выбрать</option>
            <option v-for="r in departments" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="department">Отдел</label>
        </div>

        <div class="input-field">
          <select id="user" ref="userSelect" v-model="user" @change="onSelectMember($event)">
            <option value="" disabled selected>Выбрать</option>
            <option v-for="r in userList" :key="r.id" :value="r.id">{{ r.last_name }}
              {{ r.first_name }}
            </option>
          </select>
          <label for="user">Пользователи</label>
        </div>

        <table v-if="members.length > 0" style="margin-bottom: 20px">
          <thead>
          <tr>
            <th>ФИО</th>
            <th>Водитель</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(record, idx) of members" :key="record.user.id">
            <td>
              {{ record.user.last_name }} {{ record.user.first_name }} {{ record.user.middle_name }}
            </td>
            <td>
              <label>
                <input :id="'driver_'.concat(record.user.id)" type="checkbox" class="filled-in blue darken-4" v-model="record.driver"/>
                <span></span>
              </label>
            </td>
          </tr>
          </tbody>
        </table>

        <button class="btn waves-effect waves-light blue darken-4" type="submit">
          Сохранить
          <i class="material-icons right">send</i>
        </button>
      </form>
    </section>

    <div id="modalChangeDepartment" class="modal">
      <div class="modal-content">
        <h4>Изменение отдела</h4>
        <p>
          При изменении отдела все выбранные пользователи будут удалены.<br/>
          Вы действительно хотите изменить отдел?
        </p>
      </div>
      <div class="modal-footer">
        <a class="waves-effect blue darken-4 btn-flat white-text"
           v-on:click="cancelChangeDepartment">Нет</a>
        &nbsp;&nbsp;&nbsp;
        <a class="waves-effect red darken-4 btn-flat white-text"
           v-on:click="submitChangeDepartment">Да</a>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: 'teamAdd',
  metaInfo() {
    return {
      title: this.$title('Создание бригады')
    }
  },
  data: () => ({
    loading: true,
    departments: [],
    userList: [],
    name: '',
    department: 0,
    departmentTmp: 0,
    user: [],
    members: [],
    userSelect: null,
    departmentSelect: null,
    modalChangeDepartment: null,
  }),
  validations: {
    department: {required},
    user: {required},
  },
  async mounted() {
    const departments = await this.$store.dispatch('fetchDepartments')
    this.departments = departments.filter(event => event.id > 1)

    this.loading = false

    this.modalChangeDepartment = M.Modal.init(document.querySelector('#modalChangeDepartment'));

    setTimeout(() => {
      this.userSelect = M.FormSelect.init(this.$refs.userSelect)
      this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
      M.updateTextFields()
    })
  },
  methods: {
    onChangeDepartment() {
      if (this.members.length > 0 && this.department !== 0) {
        this.modalChangeDepartment.open()
        this.departmentSelect.destroy()

        setTimeout(() => {
          this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
        })
      } else {
        this.loadUsers()
      }
    },
    async submitChangeDepartment() {
      this.departmentTmp = this.department
      this.modalChangeDepartment.close()
      this.departmentSelect.destroy()

      setTimeout(() => {
        this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
      })

      await this.loadUsers()
    },
    cancelChangeDepartment() {
      this.department = this.departmentTmp
      this.modalChangeDepartment.close()
      this.departmentSelect.destroy()

      setTimeout(() => {
        this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
      })
    },
    onSelectMember() {
      this.userList.forEach(user => {
        if (user.id === this.user) {
          this.members.push({
            "user": {
              "id": user.id,
              "last_name": user.last_name,
              "first_name": user.first_name,
              "middle_name": user.middle_name,
            },
            "driver": false,
          })
        }
      })
    },
    async loadUsers() {
      this.members = []
      this.userList = await this.$store.dispatch('fetchUsersForTeam', this.department)
      this.userSelect.destroy()

      setTimeout(() => {
        this.userSelect = M.FormSelect.init(this.$refs.userSelect)
      })
    },
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const team = {
        "department_id": this.department,
        "members": this.members,
      }

      await this.$store.dispatch('addTeam', team)
      await this.$router.push('/teams')
    },
    destroyed() {
      if (this.departmentSelect && this.departmentSelect.destroy) {
        this.departmentSelect.destroy()
      }
      if (this.userSelect && this.userSelect.destroy) {
        this.userSelect.destroy()
      }
      this.modalChangeDepartment.destroy();
    }
  },
}
</script>
